/* all */
* {
  outline: none;
  list-style: none;
  scroll-behavior: smooth;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #070324;
}
::-webkit-scrollbar-thumb:hover {
  background: #070324cc;
}

/* user selection */
::-moz-selection {
  color: #000;
  background: #fff;
}
::selection {
  color: #000;
  background: #fff;
}

/* root */
html,
body {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1C1F27;
  overflow-x: hidden;
}

/* common */
img,
a,
button,
video,
audio {
  user-select: none;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* animation */
@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes sizing {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opaciting {
  0% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
